import React, { useState } from 'react'
// import "./nav.css";
import { NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { FiPhoneCall } from "react-icons/fi";
import Logo from "../img/logo2.svg"

function Navbar() {
  const [click, setClick] = useState(false)
  const handleClick = () => setClick(!click);

  return (
    <>
      <nav className="navbar navbar1">
        <div className="navbar-container">
          <div className="nav-logo nab-logo">
            <img src={Logo} alt="" />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <a href='#home' activeClassName="active" className="nav-links" onClick={handleClick}>
                Home
              </a>
            </li>
            <li className="nav-item">
              <a href="#about" activeClassName="active" className="nav-links" onClick={handleClick}>
               About Us
              </a>
            </li>
            <li className="nav-item">
              <a href="#about-property" activeClassName="active" className="nav-links" onClick={handleClick}>
                About The Property
              </a>
            </li>
            <li className="nav-item">
              <a href='#testomials' activeClassName="active" className="nav-links" onClick={handleClick}>
                Testimonials
              </a>
            </li>
            <li className="nav-item">
              <a href='#contact-us' activeClassName="active" className="nav-links" onClick={handleClick}>
                Contact Us
              </a>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            {click ? <IoMdClose /> : <GiHamburgerMenu />}
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar