import React, { useEffect, useRef, useState } from 'react'
import Aos from 'aos'
import "aos/dist/aos.css"

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import './styles.css';

// import required modules
import { Autoplay, Pagination, Navigation, FreeMode } from 'swiper/modules';
import Card1 from "../img/ONE-CENT-HOMES-OLIVE.jpg";
import Card2 from "../img/ONE-CENT-HOMES-ORCHID.jpg";
import Card3 from "../img/ONE-CENT-03-ELEVATION-3D-VIEW_09-12-2023-pdf.io_-1024x683.webp";
import AboutUsImg from "../img/about-us-img1.webp";
import SwiperImgSmall from "../img/east-floor-plan-view-02.webp";
import CommaIcon from "../img/comma-icon.png";
import { NavLink } from 'react-router-dom';

function HomePage() {
    const [activeNav, setActiveNav] = useState("#")
    useEffect(() => {
        Aos.init({
            duration: 500
        })
    }, [])

    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };
    return (
        <div>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
                className="mySwiper" 
            >
                <SwiperSlide>
                    <div className="slider-section1" id='home'>
                        <div className="container slider-container" data-aos="fade-up">
                            <h4 className='h4'>Welcome To</h4>
                            <h1 className='heading'>Onecent Homes</h1>
                            <p>Where luxury meets affordability</p>
                            <button className='btn-orange'>DOWNLOAD BROCHURE</button>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-section2" id='home'>
                        <div className="container slider-container" data-aos="fade-up">
                            <h4 className='h4'>Welcome To</h4>
                            <h1 className='heading'>Onecent Homes</h1>
                            <p style={{ fontSize: "25px" }}>Where luxury meets affordability</p>
                            <button className='btn-orange'>DOWNLOAD BROCHURE</button>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-section3" id='home'>
                        <div className="container slider-container" data-aos="fade-up">
                            <h4 className='h4'>Welcome To</h4>
                            <h1 className='heading'>Onecent Homes</h1>
                            <p style={{ fontSize: "25px" }}>Where luxury meets affordability</p>
                            <button className='btn-orange'>DOWNLOAD BROCHURE</button>
                        </div>
                    </div>
                </SwiperSlide>

                <div className="autoplay-progress" slot="container-end">
                    <svg viewBox="0 0 48 48" ref={progressCircle}>
                        <circle cx="24" cy="24" r="20"></circle>
                    </svg>
                    <span ref={progressContent}></span>
                </div>
            </Swiper>
            <div className="card-section">
                <div className="card-list ">
                    <a href="#" className="card-item " data-aos="fade-right">
                        <img src={Card1} alt="Card Image" />
                        <h3 className='text-center'>ONECENT ORCHAD</h3>
                        <p>ELEGANT 3BHKS IN HANUMAN NAGAR, KHARMANGATH, REDEFINING LUXURY WITH SPACIOUS OUR HORIZONS LIVING AT 1520 SQFT.</p>
                        <div className="button-section text-center">
                            <button className='btn-orange'>CHECK NOW</button>
                        </div>
                    </a>
                    <a href="#" className="card-item" data-aos="fade-up">
                        <img src={Card2} alt="Card Image" />
                        <h3 className='text-center'>ONECENT O'LIVE</h3>
                        <p>STYLISH 2BHKS IN PHASE 4, VANASTALIPURAM, OFFERING MODERN LIVING WITH THE PERFECT OF COMFORT AND CONVENIENCE AT 1175 SQFT.</p>
                        <div className="button-section text-center">
                            <button className='btn-orange'>CHECK NOW</button>
                        </div>
                    </a>
                    <a href="#" className="card-item" data-aos="fade-left">
                        <img src={Card3} alt="Card Image" />
                        <h3 className='text-center'>ONECENT OORJA</h3>
                        <p>VERSATILE 2BHK AND 3BHK APARTMENTS IN MEERPET, RANGING FROM 1200 SQFT TO 1650 SQFT, ENSURING A HARMONIOUS OF SPACE AND LUXURY.</p>
                        <div className="button-section text-center">
                            <button className='btn-orange'>CHECK NOW</button>
                        </div>
                    </a>
                </div>
            </div>
           
            <div className="bg-black">
                <div className="about-us-container py-5">
                    <div className="row">
                        <div className="col-lg-5" data-aos="flip-left" id='about'>
                            <div className="about-us-text">
                                <div className="d-flex">
                                    <h6>About Us</h6>
                                    <div className="hr-line"></div>
                                </div>
                                <h3>Experience we sold over the decade.</h3>
                                <p>At Onecent homes, our story began on the west side of Hyderabad in 2020, marking the inception of a brand committed to redefining luxury living.Since our humble start, we have grown exponentially, expanding our horizons and achieving a remarkable 4x growth by the year 2023. We offer more than just apartments – a lifestyle tailored to your aspirations. Each residence is crafted with meticulous attention, adhering to Vastu principles for a harmonious living experience. We’re committed to bringing luxury living at an affordable cost without compromising quality.</p>
                                <p>Start your journey at Onecent, where affordability meets quality, and comfort meets style.</p>
                                <div className="about-us-btn">
                                    <button className='about-us-button'>REQUEST QUERY</button>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-7" data-aos="flip-right">
                            <div className="feel-img">
                                <img src={AboutUsImg} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="second-content" id='about-property' >
                        <div className="row">
                            <div className="col-lg-6" data-aos="zoom-out-down">
                                <div className="about-us-text about-new-text">
                                    <div className="d-flex">
                                        <h6>ABOUT THE PROPERTY</h6>
                                        <div className="hr-line"></div>
                                    </div>
                                    <h3>Onecent Orchad</h3>
                                    <p>Step into the future with Onecent Orchard’s ultra-modern glass front elevation design. These contemporary 3BHK apartments in Hanuman Nagar, Kharmangath, redefine luxury living at 1520 sqft. Experience the seamless connection between indoor and outdoor spaces, creating an airy, modern ambiance reminiscent of a super cool art gallery. The sleek lines and large glass panels bring a touch of sophistication, making these homes a perfect blend of art and contemporary design.</p>
                                    <div className="about-us-btn mt-5">
                                        <button className='about-us-button'>DOWNLOAD BROCHURE</button>
                                    </div>
                                    <div className="d-flex">
                                        <h3 className='mt-4'>Floor Plans</h3>
                                        <div className="hr-line mt-5"></div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-6">
                                <div className="about-us-img-2">
                                    <img src={Card2} alt="" data-aos="fade-down"
                                        data-aos-easing="linear"
                                        data-aos-duration="1500" />
                                    <Swiper
                                        spaceBetween={30}
                                        centeredSlides={true}
                                        loop={true}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        navigation={true}
                                        modules={[Autoplay, Pagination, Navigation]}
                                        onAutoplayTimeLeft={onAutoplayTimeLeft}
                                        className="mySwiperrr" data-aos="flip-left"
                                        data-aos-easing="ease-out-cubic"
                                        data-aos-duration="1500"
                                    >
                                        <SwiperSlide>
                                            <div className="swiper-img-small">
                                                <img src={SwiperImgSmall} alt="" /></div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="swiper-img-small">
                                                <img src={SwiperImgSmall} alt="" /></div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="swiper-img-small">
                                                <img src={SwiperImgSmall} alt="" /></div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="third-content">
                        <div className="row">
                            <div className="col-lg-5" data-aos="fade-right">
                                <div className="swiper-side-img">
                                    <img src={Card1} alt="" />
                                    <button className='day-view'>DAY VIEW</button>
                                    <button className='day-view night-view'>NIGHT VIEW</button>
                                </div>
                            </div>
                            <div className="col-lg-7" data-aos="fade-left">
                                <div className="about-us-text new-content">
                                    <div className="d-flex">
                                        <h6>ONGOING PROJECT</h6>
                                        <div className="hr-line"></div>
                                    </div>
                                    <h3>Onecent O'live</h3>
                                    <p>Explore the epitome of modern living at <strong>Onecent Olive</strong>. These stylish <strong>2BHK apartments</strong>in <strong>Phase 4, Vanastalipuram,</strong> boast a <strong>five-floor elevation design</strong> characterized by <strong> clean lines, sleek façade</strong>, and <strong>contemporary architectural style.</strong>Enjoy <strong>panoramic views</strong> and <strong> ample natural light,</strong>harmoniously blending modern materials like concrete, steel, and glass, creating a sophisticated and visually striking aesthetic.</p>
                                    <p><strong>Onecent Olive</strong> is a testament to the harmonious fusion of functionality and cutting-edge design.</p>
                                    <div className="about-us-btn">
                                        <button className='about-us-button'>DOWNLOAD BROCHURE</button>
                                    </div>
                                    <h3>Floor Plans</h3>
                                    <Swiper
                                        slidesPerView={2}
                                        spaceBetween={20}
                                        freeMode={true}
                                        loop={true}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        navigation={true}
                                        modules={[FreeMode, Autoplay, Navigation]}
                                        className="mySwiperr" data-aos="zoom-in-up"
                                    >
                                        <SwiperSlide >
                                            <div className="swiper-img-small swiper-second-img">
                                                <img src={SwiperImgSmall} alt="" /></div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="swiper-img-small">
                                                <img src={SwiperImgSmall} alt="" /></div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="swiper-img-small">
                                                <img src={SwiperImgSmall} alt="" /></div>
                                        </SwiperSlide>
                                    </Swiper>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="four-content mt-5">
                        <div className="row">
                            <div className="col-lg-6" data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom">
                                <div className="feel-img">
                                    <img src={AboutUsImg} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6" data-aos="fade-up"
                                data-aos-anchor-placement="center-bottom">
                                <div className="about-us-text mt-2 p-4 ongoing-project-sec">
                                    <div className="d-flex">
                                        <h6>ONGOING PROJECT</h6>

                                    </div>
                                    <h3>Onecent Oorja</h3>
                                    <p>Discover timeless elegance at <strong> Onecent Oorja</strong> in <strong>Meerpet.</strong>  With area options ranging from <strong>1200 sqft</strong> to <strong>1650 sqft,</strong> these <strong>2BHK</strong>and <strong>3BHK</strong> apartments present a classic design that evokes a sense of enduring sophistication. Immerse yourself in the charm of <strong>refined details, ensuring a perfect harmony of space and luxury.</strong></p>
                                    <p><strong>Onecent Oorja</strong> invites you to experience the distinctive allure of a classic look and feel, where every aspect resonates with timeless beauty.</p>

                                    <div className="about-us-btn">
                                        <button className='about-us-button'>CONTACT FOR MORE</button>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="five-content" data-aos="zoom-in-right">
                        <div className="text-center">
                            <h6 className='about-founders-tex'>ABOUT THE FOUNDERS</h6>
                            <p><strong>Onecent Homes </strong> is the brainchild of three visionary directors <strong>– Sai Krishna Cheripally, Sukumar Suppala, </strong> and <strong>Anirudh Konijeti.</strong> United by a family background deeply rooted in construction and various other businesses, these like-minded individuals embarked on their entrepreneurial venture after gaining valuable insights from working in their respective firms for two years. At the young age of 24, they ignited the spark that is Onecent Homes, driven by a passion to reshape residential construction. With a united commitment, they aim to establish Onecent Homes as a beacon of trust and excellence in the construction landscape of Telangana, delivering quality, affordability, and modern premium homes that stand the test of time.</p>
                        </div>
                    </div>

                    <div className="six-content" id='testomials'>
                        <div className="row">
                            <div className="col-lg-4">
                                <h6>TESIMONIALS</h6>
                                <h3>What our clients said about Onecent Homes</h3>
                            </div>
                            <div className="col-lg-7">
                                <Swiper
                                    slidesPerView={2}
                                    spaceBetween={20}
                                    freeMode={true}
                                    loop={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[FreeMode, Autoplay, Navigation]}
                                    className="mySwiperr six-swiper desktop-device"
                                >
                                    <SwiperSlide >
                                        <div className="swiper-img-small swiper-second-img" style={{ color: "#000" }}>
                                            <div className="six-content-text">
                                                <p>Our experience with Onecent Olive has been exceptional. The team's communication was prompt and clear, making the entire process stress-free. We are grateful for making the right choice with Onecent Homes.</p>
                                                <h4>MR. Anusha Reddy</h4>
                                                <div className="six-content-img d-flex">
                                                    <h6>Onecent Olive</h6>
                                                    <img src={CommaIcon} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide >
                                        <div className="swiper-img-small swiper-second-img" style={{ color: "#000" }}>
                                            <div className="six-content-text">
                                                <p>Our experience with Onecent Olive has been exceptional. The team's communication was prompt and clear, making the entire process stress-free. We are grateful for making the right choice with Onecent Homes.</p>
                                                <h4>MR. Anusha Reddy</h4>
                                                <div className="six-content-img d-flex">
                                                    <h6>Onecent Olive</h6>
                                                    <img src={CommaIcon} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide >
                                        <div className="swiper-img-small swiper-second-img" style={{ color: "#000" }}>
                                            <div className="six-content-text">
                                                <p>Our experience with Onecent Olive has been exceptional. The team's communication was prompt and clear, making the entire process stress-free. We are grateful for making the right choice with Onecent Homes.</p>
                                                <h4>MR. Anusha Reddy</h4>
                                                <div className="six-content-img d-flex">
                                                    <h6>Onecent Olive</h6>
                                                    <img src={CommaIcon} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                            <div className="col-lg-7">
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={20}
                                    freeMode={true}
                                    loop={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[FreeMode, Autoplay, Navigation]}
                                    className="mySwiperr six-swiper phone-device"
                                >
                                    <SwiperSlide >
                                        <div className="swiper-img-small swiper-second-img" style={{ color: "#000" }}>
                                            <div className="six-content-text">
                                                <p>Our experience with Onecent Olive has been exceptional. The team's communication was prompt and clear, making the entire process stress-free. We are grateful for making the right choice with Onecent Homes.</p>
                                                <h4>MR. Anusha Reddy</h4>
                                                <div className="six-content-img d-flex">
                                                    <h6>Onecent Olive</h6>
                                                    <img src={CommaIcon} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide >
                                        <div className="swiper-img-small swiper-second-img" style={{ color: "#000" }}>
                                            <div className="six-content-text">
                                                <p>Our experience with Onecent Olive has been exceptional. The team's communication was prompt and clear, making the entire process stress-free. We are grateful for making the right choice with Onecent Homes.</p>
                                                <h4>MR. Anusha Reddy</h4>
                                                <div className="six-content-img d-flex">
                                                    <h6>Onecent Olive</h6>
                                                    <img src={CommaIcon} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide >
                                        <div className="swiper-img-small swiper-second-img" style={{ color: "#000" }}>
                                            <div className="six-content-text">
                                                <p>Our experience with Onecent Olive has been exceptional. The team's communication was prompt and clear, making the entire process stress-free. We are grateful for making the right choice with Onecent Homes.</p>
                                                <h4>MR. Anusha Reddy</h4>
                                                <div className="six-content-img d-flex">
                                                    <h6>Onecent Olive</h6>
                                                    <img src={CommaIcon} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                    <div className="seventh-content">
                        <div className="text-center">
                            <h6 className='about-founders-text'>WHY ONECENT</h6>
                            <h3>Because Luxury Should Be Accessible</h3>
                        </div>
                    </div>
                </div>

                <div className="container-img-hover">
                    <div className="hover-galary-section">
                        <div className="hover-img-box" data-aos="fade-right"><h3>Prime Location</h3></div>
                        <div className="hover-img-box" data-aos="fade-up"><h3>Modern Elegance</h3></div>
                        <div className="hover-img-box" data-aos="fade-down"><h3>Premium Quality</h3></div>
                        <div className="hover-img-box" data-aos="fade-left"><h3>Luxury Pricing</h3></div>
                    </div>
                </div>
            </div>

            <div className="experts-section" id='contact-us'>
                <div className="expert-section-row">
                    <div className="row ">
                        <div className="col-lg-7" data-aos="fade-right">
                            <div className="expert-text-section">
                                <h6>HAVE QUESTIONS OR NEED PERSONALIZED <br /> GUIDANCE</h6>
                                <h3>Our team of experts is here to assist you</h3>
                                <p>Whether it’s about finding the perfect floor plan, understanding our amenities, or exploring financing options, we’re here for you.</p>
                            </div>
                        </div>
                        <div className="col-lg-5" data-aos="fade-left">
                            <div className="expert-input-section"> <br />
                                <input type="text" placeholder='NAME' required /> <br />
                                <input type="email" placeholder='EMAIL' required /> <br />
                                <input type="number" placeholder='PHONE WITH COUNTRY CODE' required /> <br />
                                <div className="about-us-btn">
                                    <button className='about-us-button'>SUBMIT REQUEST</button>
                                </div>
                            </div>
                        </div>
                    </div>
              </div>
</div>


        </div>
    )
}

export default HomePage

