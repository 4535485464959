import React from 'react'
import { useNavigate } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import Logo2 from "../img/logo2.svg";
const Footer = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className="footer-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="about-footer">
                                <img onClick={() => navigate("/")} src={Logo2} alt='' />
                                <div className="social-media-icons d-flex">
                                     <p><FaFacebookF /></p>
                                    <p><FaYoutube /></p>
                                    <p><FaInstagramSquare /></p></div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <ul className="focus-area">
                                <p>FOCUS AREAS</p>
                                <li onClick={() => navigate("/home")}><i></i> Home</li>
                                <li onClick={() => navigate("/about-us")}><i></i> About Us</li>
                                <li onClick={() => navigate("/about-property")}><i></i> About The Property</li>
                                <li onClick={() => navigate("/testimonials")}><i></i> Testimonials</li>
                                <li onClick={() => navigate("/why-choose-onecent")}><i></i> Why Choose Onecent</li>                             
                            </ul>
                        </div>
                        <div className="col-lg-3">
                            <div className="contact-footer">
                                <p>CONTACT</p>
                                <span><i class="fa-regular fa-envelope me-2"></i>info@onecent.com</span> <br />
                                <span><i class="fa-regular fa-envelope me-2"></i>+91 1212121212</span>
                              </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="sign-in-footer">
                                <p>SIGN UP FOR UPDATES</p>
                                <span>To keep yourself updated on latest news and programs at Reliance Foundation, drop in your E-mail ID</span>
                                <input type='' />
                                <button class="read-more btn submit-btn">SUBMIT</button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='terms-condition'>
                    &copy;2024. All rights reserved. Terms & Conditions. Privacy Policy.
                </div>
            </div>

        </>
    )
}
export default Footer